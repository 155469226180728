import React, { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";

function Ticketing() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <section>
        <div
          className="uk-grid-collapse uk-child-width-expand@s uk-text-left"
          data-uk-grid
        >
          <div className="">
            <div className="as-header3 uk-padding">
              <div>
                <p className="as-white uk-visible@m"> {t("TICKTE_INTRO")}</p>
                <h2 className="uk-text-bold uk-h5 as-white">
                  <Trans i18nKey="TICKTE_TEXT" />
                </h2>
                <p className=" uk-margin-large-top">
                  <a href="#" className="as-white">
                    {" "}
                    <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                      {t("TICKTE_GET")}
                    </span>{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="as-img-div uk-visible@s">
              <img
                src={require("../../../assets/img/manCar.png").default}
                className="as-header-img"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="as-qr uk-container as-parking uk-flex-center uk-padding uk-padding-remove-vertical as-margin-top-3">
        <div
          className="uk-padding uk-padding-remove-vertical uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="">
            <div className="uk-text-left uk-margin-medium-top">
              <div
                className="uk-child-width-1-1@s uk-grid-small uk-grid-match uk-flex uk-flex-center uk-flex-middle"
                data-uk-grid
              >
                <div>
                  <h3 className="uk-h3 uk-margin-top uk-padding uk-padding-remove-vertical">
                    {t("TICKTE_PARK")}
                  </h3>
                  <div className="uk-padding uk-padding-remove-vertical">
                    <div className="uk-card uk-card-default uk-border-rounded">
                      <div className="uk-padding">
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-width-auto">
                            <img
                              className=""
                              src={
                                require("../../../assets/img/warning.png")
                                  .default
                              }
                              width="30px"
                              height="30px"
                            />
                          </div>
                          <div className="uk-width-expand">
                            <p className="uk-card-title uk-margin-remove-bottom as-font-12">
                              {t("TICKTE_PARK_TEXT")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-padding uk-padding-remove-vertical">
                    <div className="uk-margin-top uk-card uk-card-default uk-border-rounded">
                      <div className="uk-padding">
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-width-auto">
                            <img
                              className=""
                              src={
                                require("../../../assets/img/list.png").default
                              }
                              width="30px"
                              height="30px"
                            />
                          </div>
                          <div className="uk-width-expand">
                            <p className="uk-card-title uk-margin-remove-bottom as-font-12">
                              {t("TICKTE_PARK_TEXT_2")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-visible@s">
            <img
              className="uk-flex uk-flex-center uk-center as-coupon"
              src={require("../../../assets/img/qr2.png").default}
            />
            <div className="as-circleImg">
              <div className="as-circle"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="as-qr uk-section uk-margin-large-top uk-container uk-flex-center uk-padding as-margin-top-2">
        <div className="uk-text-center uk-visible@m">
          <img
            className="uk-position-absolute as-z-index-minus"
            height="400px"
            width="340px"
            style={{ left: 0 }}
            src={require("../../../assets/img/greyCircle.png").default}
            alt=""
          />
        </div>
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className=" uk-visible@m">
            <div className="uk-flex uk-flex-center">
              <img
                src={require("../../../assets/img/scan.png").default}
                alt=""
                width="300px"
                height="500px"
              />
            </div>
          </div>
          <div className="">
            <div className="uk-text-left">
              <div
                className="uk-padding uk-padding-remove-vertical uk-grid-small uk-grid-match uk-flex uk-flex-center uk-flex-middle"
                data-uk-grid
              >
                <div>
                  <h3 className="uk-h3 uk-margin-top uk-padding uk-padding-remove-vertical">
                    {t("TICKTE_EL")}
                  </h3>
                  <div className="uk-padding uk-padding-remove-vertical">
                    <div className="uk-card uk-card-default uk-border-rounded">
                      <div className="uk-padding">
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-width-auto">
                            <img
                              className=""
                              src={
                                require("../../../assets/img/window.png")
                                  .default
                              }
                              width="30px"
                              height="30px"
                            />
                          </div>
                          <div className="uk-width-expand">
                            <p className="uk-card-title uk-margin-remove-bottom as-font-12">
                              {t("TICKTE_EL_TEXT")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-padding uk-padding-remove-vertical">
                    <div className="uk-margin-top uk-card uk-card-default uk-border-rounded">
                      <div className="uk-padding">
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-width-auto">
                            <img
                              className=""
                              src={
                                require("../../../assets/img/pc.png").default
                              }
                              width="30px"
                              height="30px"
                            />
                          </div>
                          <div className="uk-width-expand">
                            <p className="uk-card-title uk-margin-remove-bottom as-font-12">
                              {t("TICKTE_EL_TEXT_2")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-margin-large-top uk-container uk-flex-center uk-text-center@s">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="uk-text-center@m as-left">
            <div className="uk-text-left as-padding-right uk-margin-large-top">
              <h4> {t("TICKTE_WHAT")}</h4>
              <p className="as-font-12">{t("TICKTE_WHAT_TEXT")}</p>
              <h5> {t("TICKTE_WHAT_TEXT_2")}</h5>
              <p className="as-font-12">{t("TICKTE_FPS")}</p>
              <h4> {t("TICKTE_EXAMPLE")}</h4>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-auto uk-visible@m">
                  <img
                    src={require("../../../assets/img/check.png").default}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="uk-width-expand">
                  <span className="as-font-12">{t("TICKTE_EXAMPLE_TEXT")}</span>
                </div>
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-auto uk-visible@m">
                  <img
                    src={require("../../../assets/img/check.png").default}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="uk-width-expand">
                  <span className="as-font-12">
                    {t("TICKTE_EXAMPLE_TEXT_2")}
                  </span>
                </div>
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-auto uk-visible@m">
                  <img
                    src={require("../../../assets/img/check.png").default}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="uk-width-expand">
                  <span className="as-font-12">{t("TICKTE_BEYOND")}</span>
                </div>
              </div>
              <h4>{t("TICKTE_BEYOND_TEXT")}</h4>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-auto uk-visible@m">
                  <img
                    src={require("../../../assets/img/check.png").default}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="uk-width-expand">
                  <span className="as-font-12">{t("TICKTE_PAYMENT")}</span>
                </div>
              </div>
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-auto uk-visible@m">
                  <img
                    src={require("../../../assets/img/check.png").default}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="uk-width-expand">
                  <span className="as-font-12">{t("TICKET_AND")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="as-right-small uk-visible@m">
            <div
              className="uk-background-contain as-bg-img-1"
              style={{
                backgroundImage: `url(${
                  require("../../../assets/img/handqr.png").default
                })`,
              }}
            ></div>
          </div>
        </div>
      </section>

      <section className="as-qr uk-section uk-margin-large-top uk-container uk-flex-center uk-padding">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="uk-width-1-3@m">
            <div
              className="uk-padding uk-padding-remove-vertical uk-grid-small uk-grid-match uk-flex uk-flex-center uk-flex-middle"
              data-uk-grid
            >
              <div className="">
                <h3 className="uk-text-left uk-margin-left">
                  {t("TICKET_HOW")}
                </h3>
              </div>
            </div>
          </div>
          <div className="uk-padding uk-padding-remove-vertical uk-grid-small  uk-text-left uk-flex uk-flex-center">
            <div
              className="uk-child-width-1-2@s uk-child-width-1-2@m uk-flex uk-flex-middle"
              uk-grid="masonry: true"
            >
              <div>
                <div className="uk-card uk-card-default uk-height-medium uk-padding uk-border-rounded">
                  <img
                    src={
                      require("../../../assets/img/car-wash-blue-lineal.png")
                        .default
                    }
                    width="40px"
                    height="40px"
                    alt=""
                  />
                  <p>{t("TICKET_THE")}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-default uk-height-medium uk-padding uk-border-rounded">
                  <img
                    src={require("../../../assets/img/service.png").default}
                    width="40px"
                    height="40px"
                    alt=""
                  />
                  <p>{t("TICKET_PARKING")}</p>
                </div>
                <div className="uk-card uk-card-default uk-margin-top uk-height-medium uk-padding uk-border-rounded">
                  <img
                    src={
                      require("../../../assets/img/real-estate-blue-lineal.png")
                        .default
                    }
                    width="30px"
                    height="30px"
                    alt=""
                  />
                  <p>{t("TICKET_PRICE")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Ticketing;
