import React from "react";
import Router from "./route";
import UIKit from "./utils/uikit";
import "./i18n";
import "./redux/config";
function App() {
  return (
    <UIKit>
      <Router />
    </UIKit>
  );
}

export default App;
