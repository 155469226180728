import React, { Fragment, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

function ParkUp() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  return (
    <Fragment>
      <section className="uk-visible@s">
        <div
          className="uk-grid-large uk-child-width-expand@s uk-text-left"
          data-uk-grid
        >
          <div className="uk-height-large uk-visible@s">
            <figure className="uk-overlay-default uk-position-cover">
              <video
                poster="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/polina.jpg"
                id="bgvid"
                className="uk-overlay-default uk-position-cover"
                playsInline
                autoPlay
                muted
                loop
              >
                <source
                  src="http://thenewcode.com/assets/videos/polina.webm"
                  type="video/webm"
                />
                <source
                  src="http://thenewcode.com/assets/videos/polina.mp4"
                  type="video/mp4"
                />
              </video>
              <figcaption>
                <div className="uk-border-circle as-bg-redT">
                  <img
                    className="uk-text-center uk-position-absolute uk-margin-left-small as-player"
                    width="20px"
                    height="20px"
                    src={require("../../../assets/img/pause.png").default}
                    alt=""
                  />
                </div>
              </figcaption>
            </figure>
          </div>

          <div>
            <div className="uk-margin-xlarge-top as-margin-top-2">
              <p className="as-red">{t("ENT_SAVE_TIME")}</p>
              <h2 className="uk-text-bold">
                <Trans i18nKey="ENT_SAVE_TEXT" />
              </h2>
              <small>
                <Trans i18nKey="ENT_SAVE_TEXT_2" />
              </small>
              <p className=" uk-margin-large-top">
                <a href="#" className="as-white">
                  {" "}
                  <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                    {t("ENT_SAVE_GET")}
                  </span>{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-calm uk-margin-xlarge-top uk-padding">
        <h3 className="uk-text-uppercase uk-text-center as-blue">
          {" "}
          {t("ENT_STAY")}
        </h3>
        <p className="as-grey uk-text-center">
          <Trans i18nKey="ENT_STAY_TEXT" />
        </p>
      </section>

      <section className="as-woman uk-margin-xlarge-top uk-text-center">
        <div className="uk-container">
          <div className="uk-grid-small uk-child-width-expand@s " data-uk-grid>
            <div>
              <div className="uk-text-left uk-padding uk-margin-top as-left">
                <h4 className="uk-h4 uk-text-bold uk-text-left">
                  {" "}
                  {t("ENT_LETS")}
                </h4>
                <p className="uk-text-left">{t("ENT_LETS_TEXT")}</p>
              </div>
            </div>
            <div className="uk-visible@m">
              <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                <img
                  className="test"
                  src={require("../../../assets/img/blackMan.png").default}
                  alt=""
                  width="250px"
                  height="450px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-margin-large-top uk-container ">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="as-left-small uk-visible@s">
            <div className="">
              <img
                className="uk-position-absolute as-ce"
                src={require("../../../assets/img/womanMan.png").default}
                alt=""
                width="75%"
              />
              <img
                className="uk-position-absolute as-maps2"
                src={require("../../../assets/img/maps.png").default}
                alt=""
                width="60%"
              />
            </div>
          </div>
          <div className="">
            <div className="uk-text-left as-padding-right uk-margin-large-top">
              <ul data-uk-tab uk-switcher>
                <li className="">
                  <a
                    href="#"
                    className="as-blue uk-text-bold"
                    onClick={() => {
                      setValue(0);
                    }}
                  >
                    {t("ENT_FOR_COM")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      setValue(1);
                    }}
                    href="#"
                    className="as-blue uk-text-bold"
                  >
                    {t("ENT_FOR_EMP")}
                  </a>
                </li>
              </ul>

              {value === 0 && (
                <li>
                  <ul className="uk-list as-list uk-list-disc uk-text-left">
                    <li>{t("ENT_FOR_EMP_TEXT1")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT2")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT3")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT4")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT5")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT6")}</li>
                    <li>{t("ENT_FOR_EMP_TEXT7")}</li>
                  </ul>
                </li>
              )}
              {value === 1 && (
                <li>
                  <ul className="uk-list as-list uk-list-disc uk-text-left">
                    <li>{t("ENT_FOR_EMPL_TEXT1")}</li>
                    <li>{t("ENT_FOR_EMPL_TEXT2")}</li>
                    <li>{t("ENT_FOR_EMPL_TEXT3")}</li>
                    <li>{t("ENT_FOR_EMPL_TEXT4")}</li>
                    <li>{t("ENT_FOR_EMPL_TEXT5")}</li>
                    <li>{t("ENT_FOR_EMPL_TEXT6")}</li>
                  </ul>
                </li>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container uk-flex-center as-margin-top-2">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="as-left">
            <div className="uk-text-left uk-margin-large-top">
              <p>{t("ENT_FEATURE")}</p>
              <h1 className="uk-text-bold uk-text-uppercase">
                <Trans i18nKey="ENT_FEATURE_TEXT" />
              </h1>
              <div className="as-grey as-font-12 uk-margin-top">
                <ul className="uk-list uk-list-disc">
                  <li> {t("ENT_OFFRE1")}</li>
                  <li>{t("ENT_OFFRE2")}</li>
                  <li>{t("ENT_OFFRE3")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="as-right-small uk-visible@s">
            <img
              className="uk-flex uk-flex-center uk-center as-coupon"
              src={require("../../../assets/img/coupon.png").default}
            />
            <div className="as-circleImg">
              <div className="as-circle"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-container uk-section as-container-bg uk-margin-remove-top as-margin-top-3">
        <h1 className="uk-text-uppercase uk-text-center">
          {t("PRICE_DESCOVERY")}
        </h1>
        <div className="uk-margin-medium-top">
          <div
            className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-4@m  uk-text-center as-container"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/truck.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_PARK")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/settings.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_START")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/noteYellow.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_TICKET")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/noteGreen.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_CONSULT")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-container uk-section">
        <h1 className="uk-text-uppercase uk-text-center">
          {t("PRICE_OFFERS")}
        </h1>
        <p className="uk-text-center">{t("PRICE_OFFERS_TEXT")}</p>
        <div className="uk-margin-large-top">
          <div
            className="uk-grid-collapse uk-child-width-1-2@s uk-child-width-1-3@m  uk-text-center as-container"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-center uk-margin-remove-bottom">
                  {t("PRICE_OFFERS_TEAM")}
                </h5>
                <h2 className="uk-text-bold uk-text-center uk-margin-remove-top">
                  {t("PRICE_2000")}{" "}
                  <span className="as-font-12">{t("PRICE_FRS")}</span>
                </h2>
                <div>
                  <ul className="uk-text-left uk-list">
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_SPACE_500")}
                    </li>
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_EMAIL_100")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_WRITE")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                    </li>
                  </ul>
                  <br />
                  <a href="#" className="as-white">
                    {" "}
                    <span className="as-button-padding as-bg-white uk-box-shadow-small uk-margin-top">
                      {t("PRICE_GET")}
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <div
                  className="uk-background-cover as-red-card uk-border-rounded uk-box-shadow-large uk-panel uk-padding"
                  style={{
                    backgroundImage: `url(${
                      require("../../../assets/img/redCard.png").default
                    })`,
                  }}
                >
                  <h5 className="as-white uk-text-center uk-margin-remove-bottom">
                    {t("PRICE_PREMIUM")}
                  </h5>
                  <h2 className="as-white uk-text-bold uk-text-center uk-margin-remove-top">
                    {t("PRICE_1000")}{" "}
                    <span className="as-font-12">{t("PRICE_FRS")}</span>
                  </h2>
                  <div>
                    <ul className="uk-text-left uk-list as-white">
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_SPACE_500")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_EMAIL_100")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_UNLIMITED_BANDWIDTH")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_WRITE")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                      </li>
                    </ul>
                    <br />
                    <a href="#" className="as-red">
                      {" "}
                      <span className="as-button-padding-white as-bg-white uk-box-shadow-small uk-margin-top">
                        {t("PRICE_GET")}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-center uk-margin-remove-bottom">
                  {t("PRICE_TITLE_3")}
                </h5>
                <h2 className="uk-text-bold uk-text-center uk-margin-remove-top">
                  {t("PRICE_TITLE_3_2000")}{" "}
                  <span className="as-font-12">{t("PRICE_FRS")}</span>
                </h2>
                <div>
                  <ul className="uk-text-left uk-list">
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_SPACE_500")}
                    </li>
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_EMAIL_100")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_WRITE")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                    </li>
                  </ul>
                  <br />
                  <a href="#" className="as-white">
                    {" "}
                    <span className="as-button-padding as-bg-white uk-box-shadow-small uk-margin-top">
                      {t("PRICE_GET")}
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default ParkUp;
