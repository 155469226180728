import React from "react";

import { Router as Routers, Route, Switch } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";
import history from "../utils/history";
import Home from "../modules/home/scenes";
import Prices from "../modules/home/scenes/prices";
import ParkUp from "../modules/home/scenes/parkUp";
import Ticketing from "../modules/home/scenes/ticketing";
import How from "../modules/home/scenes/how";
import Login from "../modules/auth/scenes/login";
import Register from "../modules/auth/scenes/register";

const Router = (props: any) => {
  let routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/particuliers" component={Prices} />
      <Route exact path="/entreprises" component={ParkUp} />
      <Route exact path="/contraventions" component={Ticketing} />
      <Route exact path="/how" component={How} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
    </Switch>
  );

  return (
    <Routers history={history}>
      <Header {...props} />
      {routes}
      <Footer />
    </Routers>
  );
};
export default Router;
