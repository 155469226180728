import React, { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";
function How() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <section>
        <div
          className="uk-grid-large uk-margin-medium-top uk-child-width-1-2@s uk-child-width-1-2@m uk-text-left"
          data-uk-grid
        >
          <div className="uk-margin-xlarge-top">
            <img
              src={require("../../../assets/img/circles2.png").default}
              alt=""
              width="100%"
              height="500px"
            />
            <img
              className="uk-position-relative as-mapsP"
              src={require("../../../assets/img/maps.png").default}
              alt=""
              width="50%"
              style={{ left: "32%", top: "-50%" }}
            />
            <div className="">
              <img
                className="as-rounded-img2 uk-border-circle uk-box-shadow-large"
                src={require("../../../assets/img/roundMan.png").default}
                alt=""
                width="150px"
              />
            </div>
          </div>
          <div>
            <div className="uk-margin-xlarge-top uk-visible@s uk-padding uk-padding-remove-vertical">
              <h2 className="uk-margin-xlarge-top"> {t("HOW_WORKS")}</h2>
              <div
                className="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-left uk-flex uk-flex-middle uk-margin-medium-top"
                data-uk-grid
              >
                <div>
                  <span className="as-tips uk-border-rounded as-red as-bg-redT">
                    1
                  </span>
                  <p className="as-font-12">{t("HOW_ONE")}</p>
                </div>
                <div>
                  <span className="as-tips uk-border-rounded as-white as-bg-red">
                    2
                  </span>
                  <p className="as-font-12">{t("HOW_TWO")}</p>
                </div>
                <div>
                  <span className="as-tips uk-border-rounded as-red as-bg-redT">
                    3
                  </span>
                  <p className="as-font-12">{t("HOW_THREE")}</p>
                </div>
              </div>
              <br />
              <br />
              <a href="#" className="as-white">
                {" "}
                <span className="as-button-padding as-bg-red uk-box-shadow-medium uk-margin-top">
                  {t("HOW_GET")}
                </span>{" "}
              </a>
            </div>

            <div className="uk-margin-top uk-hidden@s uk-padding uk-padding-remove-vertical uk-margin-xlarge-bottom">
              <h2 className="uk-margin-top">{t("HOW_WORKS")}</h2>
              <div
                className="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-left uk-flex uk-flex-middle uk-margin-medium-top"
                data-uk-grid
              >
                <div>
                  <span className="as-tips uk-border-rounded as-red as-bg-redT">
                    1
                  </span>
                  <p className="as-font-12">{t("HOW_ONE")}</p>
                </div>
                <div>
                  <span className="as-tips uk-border-rounded as-white as-bg-red">
                    2
                  </span>
                  <p className="as-font-12">{t("HOW_TWO")}</p>
                </div>
                <div>
                  <span className="as-tips uk-border-rounded as-white as-bg-red">
                    3
                  </span>
                  <p className="as-font-12">{t("HOW_THREE")}</p>
                </div>
              </div>
              <br />
              <br />
              <a href="#" className="as-white">
                {" "}
                <span className="as-button-padding as-bg-red uk-box-shadow-medium uk-margin-top">
                  {t("HOW_GET")}
                </span>{" "}
              </a>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <section
        className="uk-background-cover"
        style={{
          backgroundImage: `url(${
            require("../../../assets/img/curve.svg").default
          })`,
          minHeight: "400px",
        }}
      >
        <div className="as-container as-negative">
          <h3 className="uk-text-center"> {t("HOW_ON")}</h3>
          <div
            className="uk-flex uk-grid-collapse uk-flex-center uk-text-center uk-margin-large-top-2"
            data-uk-grid
          >
            <div className="uk-width-1-3@s">
              <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
                {t("HOW_STEP_1")}
              </p>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-bold as-red">{t("HOW_STEP_1_TITLE")}</h5>
                <p>{t("HOW_STEP_1_TEXT")}</p>
              </div>
            </div>
            <div className="uk-width-1-5@s uk-visible@s">
              <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
                {t("HOW_STEP_01")}
              </small>
              <p className="as-stepper uk-margin-remove-top">01</p>
              <hr className="uk-divider-vertical" />
            </div>
            <div className="uk-width-1-3@s uk-visible@s">
              <div className="">
                <img
                  src={require("../../../assets/img/carBack.png").default}
                  alt=""
                  width="70%"
                />
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-center uk-text-center" data-uk-grid>
            <div className="uk-width-1-3@s uk-visible@s">
              <div className="">
                <img
                  src={require("../../../assets/img/carSide.png").default}
                  alt=""
                  width="70%"
                />
              </div>
            </div>
            <div className="uk-width-1-5@s uk-visible@s">
              <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
                {t("HOW_STEP_2")}
              </small>
              <p className="as-stepper uk-margin-remove-top">02</p>
              <hr className="uk-divider-vertical" />
            </div>
            <div className="uk-width-1-3@s">
              <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
                {t("HOW_STEP_02")}
              </p>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-bold as-red">{t("HOW_STEP_2_TITLE")}</h5>
                <p>{t("HOW_STEP_2_TEXT")}</p>
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-center uk-text-center" data-uk-grid>
            <div className="uk-width-1-3@s">
              <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
                {t("HOW_STEP_03")}
              </p>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-bold as-red">{t("HOW_STEP_3_TITLE")}</h5>
                <p>{t("HOW_STEP_3_TEXT")}</p>
              </div>
            </div>
            <div className="uk-width-1-5@s uk-visible@s">
              <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
                {t("HOW_STEP_3")}
              </small>
              <p className="as-stepper uk-margin-remove-top">03</p>
              <hr className="uk-divider-vertical" />
            </div>
            <div className="uk-width-1-3@s uk-visible@s">
              <div className="">
                <img
                  src={require("../../../assets/img/carBack.png").default}
                  alt=""
                  width="70%"
                />
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-center uk-text-center" data-uk-grid>
            <div className="uk-width-1-3@s uk-visible@s">
              <div className="">
                <img
                  src={require("../../../assets/img/carSide.png").default}
                  alt=""
                  width="70%"
                />
              </div>
            </div>
            <div className="uk-width-1-5@s uk-visible@s">
              <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
                {t("HOW_STEP_4")}
              </small>
              <p className="as-stepper uk-margin-remove-top">04</p>
              <hr className="uk-divider-vertical" />
            </div>
            <div className="uk-width-1-3@s">
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
                  {t("HOW_STEP_04")}
                </p>
                <h5 className="uk-text-bold as-red">{t("HOW_STEP_4_TITLE")}</h5>
                <p>{t("HOW_STEP_4_TEXT")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-container">
        <h3 className="uk-text-center">{t("HOW_PARK")}</h3>
        <div
          className="uk-flex uk-flex-center uk-text-center uk-margin-large-top"
          data-uk-grid
        >
          <div className="uk-width-1-3@s">
            <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
              {t("HOW_PARK_01")}
            </p>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-text-bold as-red">{t("HOW_PARK_01_TITLE")}</h5>
              <p>{t("HOW_PARK_01_TEXT")}</p>
            </div>
          </div>
          <div className="uk-width-1-5@s uk-visible@s">
            <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
              {t("HOW_PARK_1")}
            </small>
            <p className="as-stepper uk-margin-remove-top">01</p>
            <hr className="uk-divider-vertical" />
          </div>
          <div className="uk-width-1-3@s uk-visible@s">
            <div className="">
              <img
                src={require("../../../assets/img/carBack.png").default}
                alt=""
                width="70%"
              />
            </div>
          </div>
        </div>

        <div className="uk-flex uk-flex-center uk-text-center" data-uk-grid>
          <div className="uk-width-1-3@s uk-visible@s">
            <div className="">
              <img
                src={require("../../../assets/img/carSide.png").default}
                alt=""
                width="70%"
              />
            </div>
          </div>
          <div className="uk-width-1-5@s uk-visible@s">
            <small className="uk-text-uppercase as-grey3 uk-margin-remove-bottom">
              {t("HOW_PARK_2")}
            </small>
            <p className="as-stepper uk-margin-remove-top">02</p>
            <hr className="uk-divider-vertical" />
          </div>
          <div className="uk-width-1-3@s">
            <p className="uk-hidden@s uk-text-bold uk-margin-remove-top uk-text-uppercase">
              {t("HOW_PARK_02")}
            </p>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-text-bold as-red">{t("HOW_PARK_02_TITLE")}</h5>
              <p>{t("HOW_PARK_02_TEXT")}</p>
            </div>
          </div>
        </div>

        <div className="as-side-img uk-visible@s">
          <img
            className="uk-position-absolute"
            height="80px"
            width="80px"
            style={{ right: 0 }}
            src={require("../../../assets/img/ovalRight.png").default}
            alt=""
          />
        </div>

        <div
          className="uk-flex uk-flex-center uk-text-center uk-margin-top@s"
          data-uk-grid
        >
          <div className="uk-width-1-3@s">
            <p className="uk-hidden@s uk-text-bold uk-margin-top uk-text-uppercase">
              {t("HOW_PARK_03")}
            </p>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
              <h5 className="uk-text-bold as-red"> {t("HOW_PARK_03_TITLE")}</h5>
              <p>{t("HOW_PARK_03_TEXT")}</p>
            </div>
          </div>
          <div className="uk-width-1-5@s uk-visible@s">
            <small className="uk-text-uppercase as-grey3 uk-margin-medium-top uk-margin-remove-bottom">
              {t("HOW_PARK_3")}
            </small>
            <p className="as-stepper uk-margin-remove-top">03</p>
            <hr className="uk-divider-vertical" />
          </div>
          <div className="uk-width-1-3@s uk-visible@s">
            <div className="">
              <img
                src={require("../../../assets/img/carBack.png").default}
                alt=""
                width="70%"
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default How;
